import cornerstone from 'cornerstone-core'
export function CambiarProceso(nombre) {
    let ImgVp = cornerstone.getViewport(document.querySelector(".Dicom"))
  switch (nombre) {
    case "Vflip":
        ImgVp.hflip = ImgVp.hflip?false:true
        break;
        case "Hflip":
            ImgVp.vflip = ImgVp.vflip?false:true
      break;
    case "RsH":
        ImgVp.rotation = ImgVp.rotation + 90
      break;
    case "RaH":
        ImgVp.rotation = ImgVp.rotation - 90
      break;
    case "mulculo":
        ImgVp.voi = {windowCenter:25,windowWidth:350}
      break;
    case "pulmon":
        ImgVp.voi = {windowCenter:-600,windowWidth:1500}
      break;
    case "hueso":
        ImgVp.voi = {windowCenter:292,windowWidth:1198}
      break;
    case "cerebro":
        ImgVp.voi = {windowCenter:40,windowWidth:80}
      break;
      case "center":
        let def1 = JSON.parse(window.sessionStorage.getItem("DefaultViewPort"))
        ImgVp.scale = def1.scale
        ImgVp.translation = def1.translation
      break;
    default:
        //console.log(ImgVp)
        let def = JSON.parse(window.sessionStorage.getItem("DefaultViewPort"))
        ImgVp = def
      break;
  }
  cornerstone.setViewport(document.querySelector(".Dicom"),ImgVp)
  cornerstone.updateImage(document.querySelector(".Dicom"));
}

import React, { useEffect, useState } from "react";
import * as funciones from "./funcionesMain";
import "./Main.scss";
import Logo1 from "./SPINNER1B.png";
import Logo2 from "./SPINNER2B.png";
import { PDFReader } from 'reactjs-pdf-reader';


function Main(props) {
  const [ConfGeneral] = useState(
    JSON.parse(window.localStorage.getItem("ConfiguracionGeneral"))
  );
  const [PrimeraImagen, setPrimeraImagen] = useState(false);
  const [ListaImages, setListaImages] = useState([]);
  const [ImgNav, setImgNav] = useState(0);
  const [CargandoImagenes, setCargandoImagenes] = useState(true);
  let imagenes = props.Estudio.SERIES[props.EstudioSeleccionado];

  function decNav(ImgNav){
    if (ImgNav - 1 >= 0) {
      setImgNav(ImgNav - 1);
    } else {
      funciones.verAlerta("Primera Imagen", "Peligro");
    }
  }
  
  let PDFDownload = ()=>{
    let Url = URL.createObjectURL(props.PdfBlob)
    let Link = document.createElement("a")
    Link.href = Url;
    Link.setAttribute('download', 'informe.pdf');
    Link.click();
  }
  //navegacion de iagenes
  let SiguiteImagen = () => {
    funciones.incNav(ImgNav,ListaImages,imagenes,setImgNav)
  };
  let PreviaImagen = () => {
    funciones.decNav(ImgNav,setImgNav)
  };
  //Teclado
let KeyUp = (e)=>{
  if(e.keyCode===38){
    funciones.incNav(ImgNav,ListaImages,imagenes,setImgNav)
  }
  if(e.keyCode===40){
    funciones.decNav(ImgNav,setImgNav)
  }
  //console.log(e.keyCode)
}
let wheelaccion =(e)=>{
  if(props.HerrDes[1].actual === "SCROLL"){
    e.deltaY>0?funciones.incNav(ImgNav,ListaImages,imagenes,setImgNav):funciones.decNav(ImgNav,setImgNav)
  }
}
  //use de efectos
  useEffect(() => {
    //inicio de descarga
    var controller = new AbortController();
    var signal = controller.signal;
    funciones.verAlerta("inicia Descarga", "Notificasion");
    funciones
      .CargarImagenes(imagenes, setPrimeraImagen, setListaImages,signal)
      .then(() => {
        if(!signal.aborted){
            setCargandoImagenes(false);
            funciones.verAlerta("Descarga Finalizada", "Notificasion");
        }
      });
    return () => {
      //console.log("Efecto1");
      controller.abort();
      setImgNav(0)
      setPrimeraImagen(false)
      setCargandoImagenes(true)
      //reset primera imagen
      //reset ImgNav
    };
  }, [props.EstudioSeleccionado]);
  useEffect(() => {
    //ver primera imagen
    if (PrimeraImagen) {
      funciones.verPrimeraImagen(ListaImages, 0, 0);
    }
    return () => {
      //
    };
  }, [PrimeraImagen]);
//activacion herramientas
  useEffect(() => {
    if (PrimeraImagen) {
        funciones.activarHerramientas(props.HerrMobil,props.HerrDes)
    }
  }, [PrimeraImagen,props.HerrMobil,props.HerrDes])
//navegacion de images
  useEffect(() => {
    if (PrimeraImagen) {
      funciones.verimagen(ListaImages, ImgNav, 0);
    }
    return () => {
      //
    };
  }, [ImgNav]);
  useEffect(() => {
    var intervalo;
    if (props.Cinema.go) {
      let i = ImgNav;
      let largo = ListaImages.length
      intervalo = setInterval(() => {
        if (i + 1 < largo) {
            setImgNav(i+1);
            if(!props.Cinema.go){
                clearInterval(intervalo);
            }
        }else{
            if(largo!==imagenes.FILES.length){
                funciones.verAlerta("Descargando Imagenes", "Advertencia");
                props.onCinemaCrash();
                clearInterval(intervalo);
            }else{
                i=0
                setImgNav(i+1);
            }
        }
        i++;
      }, 80);
    }
    return () => {
        if(props.Cinema.go){
            clearInterval(intervalo);
        }
    };
  }, [props.Cinema]);
  return (
    <div className="Main" onKeyUp={KeyUp} tabIndex="0" onWheel={wheelaccion}>
      <div className="MainNav next" onClick={SiguiteImagen}>
        <i>arrow_forward_ios</i>
      </div>
      <div className="MainNav prev" onClick={PreviaImagen}>
        <i>arrow_back_ios</i>
      </div>
      <div className="Dicom"></div>
      <div className="Etiquetas top left">
        <div>{props.Estudio.NOMBRE}</div>
        <div>{props.Estudio.SEXO}</div>
        <div>Edad:</div>
        <div>
          {ImgNav + 1}/{imagenes.FILES.length}
        </div>
        {CargandoImagenes && (
          <LoadingImg imagenes={imagenes} ListaImages={ListaImages} />
        )}
      </div>
      <div className="Etiquetas top right">
        <div className="Logo">
          <img
            style={{ objectFit: "contain",fill:"white" }}
            width="100%"
            height="100%"
            src="/IMG/logoletrasC.svg"
            alt={ConfGeneral.NombreInstitucion}
          />
        </div>
      </div>
      <div className="Etiquetas bottom left">
        <div>{ConfGeneral.NombreInstitucion}</div>
      </div>
      <div className="Etiquetas bottom right">
        <div className="Etiquetasbottomright">
          {/*<i>camera_alt</i>*/}
        </div>
      </div>
      <div className={`PDF ${props.VerInforme ? "activo" : ""}`}>
      <PdfViewer Blob={props.PdfBlob} onDownload={PDFDownload}/>
      </div>
      <Warning />
    </div>
  );
}
let LoadingImg = (props) => {
  return (
    <div className="LoadingImg">
      <img
        className="LoadingImgSpinner1"
        src={Logo1}
        width="30px"
        height="30px"
        alt="a"
      />
      <img
        className="LoadingImgSpinner2"
        src={Logo2}
        width="10px"
        height="30px"
        alt="a"
      />
      <div className="Texto">
        <div>Cargando</div>
        <div className="TextAvance">
          {props.ListaImages.length}/{props.imagenes.FILES.length}
        </div>
      </div>
    </div>
  );
};
let Warning = () => {
  return <div id="Alerta" className="Warning"></div>;
};
let PdfViewer = (props)=>{
  let contenido
  if(props.Blob !== undefined){
    contenido=<PDFReader showAllPage={true}  url={`${URL.createObjectURL(props.Blob)}`}/>
  }else{
    contenido="Cargando informe"
  }
  return(
    <div className="PdfViewer">
    <div className="PDFcabecera">
      <button onClick={props.onDownload}>Descargar</button>
    </div>
    <div className="PDFcontenedor">
      {contenido}
    </div>
    </div>
  )
}
export default Main;
